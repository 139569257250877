import React from "react";
import TripTable from "./TripTable"

const Dashboard = () => {
  return (
    <div>
      <p>Dashboard Work</p>
      <TripTable />
    </div>
  );
};

export default Dashboard;
